$(document).on "turbolinks:load", () ->
  if $('#customer_invoice_side_panel').find('#customer_invoice_show').length
     $ -> window.open_show_modal()

  $('.date-range-picker').daterangepicker
    showDropdowns: true
    autoUpdateInput: false
    locale:
      format: 'DD/MM/YYYY'
      cancelLabel: 'Clear'
  $('.date-range-picker').on 'apply.daterangepicker', (ev, picker) ->
    $(this).val picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY')
    $('.date-range-picker').change()
    return
  $('.date-range-picker').on 'cancel.daterangepicker', (ev, picker) ->
    cancel_datarangepicker($(this))

  $('.date-range-picker-2').daterangepicker
    showDropdowns: true
    autoUpdateInput: false
    locale:
      format: 'DD/MM/YYYY'
      cancelLabel: 'Clear'
  $('.date-range-picker-2').on 'apply.daterangepicker', (ev, picker) ->
    $(this).val picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY')
    $('.date-range-picker-2').change()
    return
  $('.date-range-picker-2').on 'cancel.daterangepicker', (ev, picker) ->
    cancel_datarangepicker($(this))

cancel_datarangepicker = ($daterangepicker) ->
  today = get_today_date()
  $daterangepicker.data('daterangepicker').setStartDate(today);
  $daterangepicker.data('daterangepicker').setEndDate(today);
  $daterangepicker.val ''
  $daterangepicker.change()
  $daterangepicker.parent().addClass('hidden').width('')
  $daterangepicker.parent().siblings().removeClass('hidden')
  return

get_today_date = () ->
  today = new Date
  dd = today.getDate()
  mm = today.getMonth() + 1
  yyyy = today.getFullYear()
  if dd < 10
    dd = '0' + dd
  if mm < 10
    mm = '0' + mm
  today = dd + '/' + mm + '/' + yyyy
  return today

$(document).on 'click', '.daterangepicker-date', () ->
  $(this).addClass('hidden')
  $(this).next().removeClass('hidden')
  if $(this).data("type") == "inward_date"
    $('.date-range-picker-2').click()
    $(this).siblings('.daterangepicker-input').find('.date-range-picker-2').addClass('drp-active')
  else
    $('.date-range-picker').click()
    $(this).siblings('.daterangepicker-input').find('.date-range-picker').addClass('drp-active')

$(document).on 'click', '.daterangepicker-input', () ->
  $(this).find('.date-range-picker').addClass('drp-active')
  $(this).find('.date-range-picker-2').addClass('drp-active')

$(document).click (event) ->
  unless event.target.closest('.daterangepicker, .daterangepicker-container')
    if $('.daterangepicker-container').find('.date-range-picker').val() == ''
      $('.date-range-picker').closest('.daterangepicker-container').find('.daterangepicker-input').addClass('hidden')
      $('.date-range-picker').closest('.daterangepicker-container').find('.daterangepicker-date').removeClass('hidden')
    if $('.daterangepicker-container').find('.date-range-picker-2').val() == ''
      $('.date-range-picker-2').closest('.daterangepicker-container').find('.daterangepicker-input').addClass('hidden')
      $('.date-range-picker-2').closest('.daterangepicker-container').find('.daterangepicker-date').removeClass('hidden')

$(document).ready ->
  $('#customer_invoice_invoice_date').datepicker(
    todayBtn: 1
    autoclose: true).on 'changeDate', (selected) ->
    minDate = new Date(selected.date.valueOf())
    if $('#customer_invoice_inward_date').val() < $('#customer_invoice_invoice_date').val()
      $('#customer_invoice_inward_date').val('')
    $('#customer_invoice_inward_date').datepicker 'setStartDate', minDate
    return
  return
