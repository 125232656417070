$(document).on 'click', '[data-behaviour="edit_order_item"]', () ->
  $(this).prop('disabled', true).text('Loading ...').prepend("<i class='fa fa-spinner fa-spin mr-2'></i>")
  front_rear = $(this).data('front_rear')
  order_item_id = $(this).data('order-item-id')
  order_id = $(this).data('order-id')
  $.ajax '/orders/' + order_id + '/edit_order_item',
    type: 'PATCH'
    data: {
      alignment: front_rear,
      i7_order_item_id: order_item_id,
    }

$(document).on 'click', '[data-behaviour="remove_i7_order_items"]', () ->
  id = $(this).data('order-item-id')
  alignment = $(this).data('alignment')
  $('[data-behaviour="edit_order_item"]').attr('data-front_rear', alignment)
  $(this).parent().remove()
  if $('#items_'+ id).length == 1
    $('[data-behaviour="remove_i7_order_items"]').addClass('hidden')
  if $('#items_'+ id).length == 0
    $('[data-behaviour="remove_i7_order_items"]').addClass('hidden')
    $('[data-behaviour="edit_order_item"]').addClass('disabled pointer-event-none')

$(document).on 'click', '[data-behaviour="i7order_edit"]', () ->
  order_id = $(this).data('order-id')
  i7_order_item_id = $(this).data('i7-order-id')
  $.ajax '/orders/' + order_id + '/render_edit_modal',
    type: 'GET'
    data: {
      i7_order_item_id: i7_order_item_id,
    }
    success: (data, jqxhr, textStatus) ->
      $.when($('#render_i7_order_items_' + i7_order_item_id).html data).then ->
        $('#edit-order-item_' + i7_order_item_id).modal('show')


$(document).on 'click', '.add_i9_order', () ->
  order_date = $('[data-behaviour="select-order-date"]').val()
  $ -> append_i9_part_no(order_date)


$(document).on 'change', '[data-behaviour="select-order-date"]', () ->
  order_date = $(this).val()
  $ -> append_i9_part_no(order_date)


append_i9_part_no = (order_date) ->
  $.ajax '/orders/get_i9_versions',
    type: 'GET'
    data: {
      order_date: order_date
    }
    success: (data, jqxhr, textStatus) ->
      $('[data-field="sub_assembly"]').append(new Option(''))
      input_fields = $('[data-field="sub_assembly"]').length
      $(data).each ->
        if input_fields > 1
          $('[data-field="sub_assembly"]')[input_fields - 1].append(new Option($(this)[0].sub_assembly_part_no, $(this)[0].id))
        else
          $('[data-field="sub_assembly"]').append(new Option($(this)[0].sub_assembly_part_no, $(this)[0].id))


$(document).on 'click', '[data-behaviour="edit_i7_sequence"]', () ->
  $(this).prop('disabled', true).text('Loading ...').prepend("<i class='fa fa-spinner fa-spin mr-2'></i>")
  i7_ids_values = []
  input_value = []
  order_id = $(".sequence-order-id").data('order-id')
  $(".edit-input").each ->
   if $(this).val() != ""
     i7_id_value= [$(this).data("i7-id"), $(this).val()]
     i7_ids_values.push(i7_id_value)
     input_value.push($(this).val())
  if checkIfArrayIsUnique(input_value)
    current_request = $.ajax '/i7_order_items/update_sequence',
    type: 'POST'
    data: {
      i7_ids_values: i7_ids_values
      order_id: order_id
    }
    error: (json, jqxhr, textStatus) ->
      data = JSON.parse(json.responseText)
      $.each data.error_ids, (index, value) ->
        $('[' + 'data-i7-id=' + value + ']').addClass('edit-sequence-error-field')
      $(".order-custom-validations").text("The highlighted sequence no is already taken.")
  else
     $(".order-custom-validations").text("Duplicate sequence no entries found.")
  $(this).prop('disabled', false).text('Save changes')

$(document).on 'keyup', '[data-behaviour="enter_sequence_no"]', () ->
  value_entered = false
  $(".edit-input").each ->
   if $(this).val() != ""
     value_entered = true
  if value_entered
    $('[data-behaviour="edit_i7_sequence"]').removeClass("disabled pointer-event-none")
  else
    $('[data-behaviour="edit_i7_sequence"]').addClass("disabled pointer-event-none")

checkIfArrayIsUnique = (array) ->
  array.sort()
  i = 1
  while i < array.length
    if array[i - 1] == array[i]
      return false
    i++
  true

$(document).on 'click', '[data-behaviour="order-item-tabs"]', () ->
  $('[data-behaviour="order-item-tabs"]').removeClass('active')
  $(this).addClass('active')
  order_id = $('.order_id_hidden').data('order-id')
  tab = $(this).data('tab')
  $.ajax '/orders/' + order_id,
    type: 'GET'
    data: {
      tab: tab
    }
    success: (data, jqxhr, textStatus) ->
      $('#order_item_show').html data

$(document).on 'click', '[data-add="i9-order-item"], [data-remove="i9-order-item"]', () ->
  setTimeout (->
    i9_order_item_el = $('#i9_order_items .s-no')
    i9_order_item_el.each (index) ->
      $(this).text(index+1)
    return
  ), 10

$(document).on 'click', '[data-edit="i9-order-item"]', () ->
  order_id = $(this).data('order-id')
  i9_order_item_id = $(this).data('i9-order-item-id')
  $.ajax '/i9_order_items/' + i9_order_item_id,
    type: 'GET'
    data: {
      i9_order_item_id: i9_order_item_id,
    }
    success: (data, jqxhr, textStatus) ->
      $('#render_edit_i9_order_item_form').html data

$(document).on 'click', '[data-new="i9-order-item"]', () ->
  $('[data-add="additional-i9-order-item"]').click()
  $('[data-select="part"]').select2()
  $(".form-table-container").find(".remove_fields:first").hide();

$(document).on 'click', '[data-add="additional-i9-order-item"]', () ->
  setTimeout (->
    i9_order_item_el = $('#add_i9_order_items .s-no')
    i9_order_item_el.each (index) ->
      $(this).text(index+1)
    $('[data-select="part"]').each ->
      $(this).select2()
  ), 10

# $(document).on 'click', '[data-tab="order-form"]', () ->
#   tab = $(this).children().attr("href")
#   if tab == '#upload-protocol'
#     $('[data-upload="file"]').addClass("input_validation")
#   else if tab == '#add-i9s'
#     $('[data-upload="file"]').removeClass("input_validation")

# $(document).on 'change', '[data-upload="file"]', (e) ->
#   $(this).removeClass("p-4").addClass("p-2")
#   $(this).parent().addClass('has-file')
#   $('.upload-text-container .icon-upload').addClass('hidden')
#   $('.upload-text-container .upload-text').addClass('hidden')
#   $('.select-text-container i.fa-light.fa-xmark').removeClass('hidden')
#   $('.select-text-container .upload-text').removeClass('hidden').text(e.target.files[0].name)


# $(document).on 'click', '[data-unselect="file"]', () ->
#   $('[data-upload="file"]').val("").removeClass("p-2").addClass("p-4")
#   $('[data-upload="file"]').parent().removeClass('has-file')
#   $('.upload-text-container .icon-upload').removeClass('hidden')
#   $('.upload-text-container .upload-text').removeClass('hidden')
#   $('.select-text-container i.fa-light.fa-xmark').addClass('hidden')
#   $('.select-text-container .upload-text').addClass('hidden')
