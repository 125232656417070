$(document).on "turbolinks:load", () ->
  if $('.side_panel').find('#i9_sub_assembly_show').length
    $ -> window.open_show_modal()

$(document).on 'change', '[data-select="part-type"]', () ->
  part_type_el = $(this)
  $.ajax '/i9_sub_assemblies/get_supplier_parts.json/',
    type: 'GET'
    data: {
      part_type: part_type_el.val()
    }
    success: (data, jqxhr, textStatus) ->
      supplier_el = part_type_el.closest('.field').siblings().find('[data-select="supplier"]')
      part_type_el.closest('.field').siblings().find('[data-append="parts"]').empty()
      supplier_el.empty().append('<option value=""></option>')
      $.each data, (key, value) ->
        supplier_el.append('<option value="' + value[0] + '">' + value[1] + '</option>')

$(document).on 'change', '[data-select="supplier"]', () ->
  supplier_el = $(this)
  part_type = supplier_el.closest('.field').siblings().find('[data-select="part-type"]').val()
  unless part_type == undefined
    $.ajax '/i9_sub_assemblies/get_supplier_parts.json/',
      type: 'GET'
      data: {
        supplier_name: supplier_el.val(),
        part_type:part_type
      }
      success: (data, jqxhr, textStatus) ->
        $('[data-path-name="i9_versions"]').prop('disabled', false)
        part_el = supplier_el.closest('.field').siblings().find('[data-append="parts"]')
        part_el.empty().append('<option value=""></option>')
        $.each data, (key, value) ->
          part_el.append('<option value="' + value.id + '">' + value.part_no + '</option>')

$(document).on 'click', '[data-add="i9-version-item"]', () ->
  if $('.nested-fields .version_part_counter').length >= 2
    $(this).addClass('hidden')
  part_el = $('[data-select="part-type"]')
  supplier_el = $('[data-select="supplier"]')
  part_el.each (index) ->
    $(this).select2 'destroy'
    $(this).attr("id", "part_type-" + index).select2()
  supplier_el.each (index) ->
    $(this).select2 'destroy'
    $(this).attr("id", "supplier-" + index).select2()

$(document).on 'click', '[data-remove="i9-version-item"]', () ->
  if $('.nested-fields .version_part_counter').length <= 3
    $('[data-add="i9-version-item"]').removeClass('hidden')

$(document).on 'change', '[data-append="parts"]', () ->
  $(this).attr("data-part-id", $(this).val())
  $('[data-path-name="i9_versions"]').prop('disabled', false)

$(document).on 'change', '[data-select="part-type"]', () ->
  $(this).attr("data-type", $(this).val())
  $('[data-path-name="i9_versions"]').prop('disabled', false)

$(document).on 'click', '[data-target="#edit-sub-assembly-modal"]', () ->
  i9_sub_assembly_id = $(this).data('i9-sub-assembly-id')
  $.ajax '/i9_sub_assemblies/' + i9_sub_assembly_id + '/i9_active_version',
    type: 'GET'
    success: (data, jqxhr, textStatus) ->
      $('#i9_sub_assembly_form').html data
      $('.datepicker').datepicker({
        format: 'M dd, yyyy',
        startDate: "+1d",
        autoclose: true,
      }).attr('readonly', true)
      $('.select-2').select2()
      $('[data-path-name="i9_versions"]').prop('disabled', true)
      $('[data-class-name="start_date"]').val('')
