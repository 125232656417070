$(document).on "turbolinks:load", () ->
  if $('#odc_side_panel').find('#odc_show').length
    $ -> window.open_show_modal()

$(document).on 'change', '[data-select="supplier"]', (e) ->
  supplier_el = $(this)
  $(this).closest('.field').siblings().find('[data-fill="quantity"]').val('')
  $.ajax '/parts/get_supplier_parts.json',
    type: 'GET'
    data: {
      supplier_name: $(this).val()
    }
    success: (data, jqxhr, textStatus) ->
      el = supplier_el.parent().parent().find('.odc_part')
      if data == null
        el.empty()
      else if data.length > 1
        el.empty().append('<option value=""></option>')
      else if data.length == 1
        el.empty().attr("data-part-id", data[0].id)
      $.each data, (key, value) ->
        el.append('<option value="' + value.id + '">' + value.part_no + '</option>')

$(document).on 'click', '[data-add="outward-dc-item"]', () ->
  outward_dc_el = $('[data-select="supplier"]')
  outward_dc_el.each (index) ->
    $(this).select2 'destroy'
    $(this).attr("id", "supplier-" + index).select2()

$(document).on 'click', '[data-target="#new-odc-modal"]', () ->
  $('.form-control').val('').removeClass('is-valid')
  $('.nested-fields').each (index) ->
    if index > 0
      $(this).remove()
  $('.select-2').select2()
  $('input[name="_method"]').attr("value", "post")
