$(document).on 'click', '[data-target="#edit-user-modal"]', () ->
  user_id = $(this).data('user-id')
  request_url = '/users/' + user_id
  $.ajax request_url,
    type: 'GET'
    dataType: 'HTML'
    success: (data, jqxhr, textStatus) ->
      $.when($('.edit-user-content').html data).then ->
        $('.select-2').select2()
        $ -> window.select2_form_input_ui()
    error: (json, jqxhr, textStatus, errorThrown) ->
      $.jGrowl('Parts was successfully created.', {theme: 'success'})

$(document).on 'click', '[data-target="#destroy-user-modal"]', () ->
  user_id = $(this).data('user-id')
  request_url = '/users/' + user_id
  $.ajax request_url,
    type: 'GET'
    dataType: 'JSON'
    success: (data, jqxhr, textStatus) ->
      $('.destroy-user-content b').html(" " + data.email)
      $('.destroy-confirm-btn').data('user-id', data.id)
    error: (json, jqxhr, textStatus, errorThrown) ->
      $.jGrowl('Parts was successfully created.', {theme: 'success'})

$(document).on 'click', '.destroy-confirm-btn', () ->
  user_id = $(this).data('user-id')
  request_url = '/users/' + user_id
  $.ajax request_url,
    type: 'DELETE'
    dataType: 'HTML'
    success: (data, jqxhr, textStatus) ->
      location.href = '/users'
    error: (json, jqxhr, textStatus, errorThrown) ->
      $.jGrowl('Parts was successfully created.', {theme: 'success'})


$(document).on 'keypress', '[data-behaviour="digits-only"]', (e) ->
  if e.which != 8 and e.which != 0 and (e.which < 48 or e.which > 57)
    return false
  if ($('[data-behaviour="digits-only"]').is(':visible') && $('[data-behaviour="digits-only"]').val().length > 9)
    return false
