$(document).on "turbolinks:load", () ->
  if $('#invoice_side_panel').find('#invoice_detail_section').length
    $ -> window.open_show_modal()
  $('.invoice-status-select .select2-search__field').attr('placeholder', 'Status').addClass('placeholder-style')

$(document).on 'click', '[data-add="invoice-item"], [data-remove="invoice-item"]', () ->
  setTimeout (->
    $('.s-no, .invoice-s-no').each (index) ->
      $(this).text(index+1)
    return
  ), 10

$(document).on 'change', '[data-behaviour="show-invoice-items-field"]', () ->
  order_type = $(this).find(':selected').attr('order_type')
  invoice_no = $('[data-class-name="invoice_no"]').val()
  invoice_date = $('[data-class-name="invoice_date"]').val()
  vehicle_id = $('[data-class-name="vehicle_no"]').val()
  order_id = $('[data-class-name="order_no"]').val()
  $.ajax '/invoices/new',
    type: 'GET'
    data: {
      order_type: order_type,
      invoice_no: invoice_no,
      invoice_date: invoice_date,
      vehicle_id: vehicle_id,
      order_id: order_id
    }
    success: (data, jqxhr, textStatus) ->
      $('#invoice_form').html data
      $('.datepicker').datepicker({
        format: 'M dd, yyyy',
        todayHighlight: true,
        autoclose: true,
      })
      $ -> window.select2_form_input_ui()
      $('.select-2').select2()
      $(".form-table-container").find(".remove_fields:first").hide()

$(document).on 'change', '[data-behaviour="invoice_i7_protocol_no"]', () ->
  $(this).attr("data-invoice-no", $(this).val())
  chassis_no_field = $(this).closest('.field').siblings('.invoice-details').find('[data-invoice="invoice_chassis_no"]')
  front_i9_field = $(this).closest('.field').siblings('.invoice-details').find('[data-invoice="invoice_front_i9_version_id"]')
  rear_i9_field = $(this).closest('.field').siblings('.invoice-details').find('[data-invoice="invoice_rear_i9_version_id"]')
  chassis_no_field.val($(this).find(':selected').attr('chassis_no'))
  i9_dropdown = $(this).closest('.field').siblings('.i9-dropdown').find('[data-append="I9"]')
  i9_dropdown.empty().append('<option value=""></option>')
  #this array contains 3 values: [is_invoiced, verison_id, i9_no]
  front_i9_version = $(this).find(':selected').attr('front_i9_version_details').split(" ")
  rear_i9_version = $(this).find(':selected').attr('rear_i9_version_details').split(" ")
  if front_i9_version[0] == "false" && rear_i9_version[0] == "false"
    if front_i9_version[1].length != 0 && rear_i9_version[1].length != 0
      i9_dropdown.append('<option value="' + front_i9_version[1] + '" value_for="front">' + "Front (" + front_i9_version[2] + ")" + '</option>')
      i9_dropdown.append('<option value="' + rear_i9_version[1] + '" value_for="rear">' + "Rear (" + rear_i9_version[2] + ")" + '</option>')
      i9_dropdown.append('<option value="' + [front_i9_version[1], rear_i9_version[1]] + '" value_for="both">' + "Both (" + front_i9_version[2] + ", " + rear_i9_version[2] + ")" + '</option>')
    else if front_i9_version[1].length != 0 && rear_i9_version[1].length == 0
      i9_dropdown.append('<option value="' + front_i9_version[1] + '" value_for="front">' + "Front (" + front_i9_version[2] + ")" + '</option>')
      i9_dropdown.val(front_i9_version[1])
      front_i9_field.val(front_i9_version[1])
    else if front_i9_version[1].length == 0 && rear_i9_version[1].length != 0
      i9_dropdown.append('<option value="' + rear_i9_version[1] + '" value_for="rear">' + "Rear (" + rear_i9_version[2] + ")" + '</option>')
      i9_dropdown.val(rear_i9_version[1])
      rear_i9_field.val(rear_i9_version[1])
  else if front_i9_version[0] == "false" && rear_i9_version[0] == "true"
    i9_dropdown.append('<option value="' + front_i9_version[1] + '" value_for="front">' + "Front (" + front_i9_version[2] + ")" + '</option>')
    i9_dropdown.val(front_i9_version[1])
    front_i9_field.val(front_i9_version[1])
  else if front_i9_version[0] == "true" && rear_i9_version[0] == "false"
    i9_dropdown.append('<option value="' + rear_i9_version[1] + '" value_for="rear">' + "Rear (" + rear_i9_version[2] + ")" + '</option>')
    i9_dropdown.val(rear_i9_version[1])
    rear_i9_field.val(rear_i9_version[1])

$(document).on 'change', '[data-append="I9"]', () ->
  value_for = $(this).find(':selected').attr('value_for')
  front_i9_field = $(this).closest('.field').siblings('.invoice-details').find('[data-invoice="invoice_front_i9_version_id"]')
  rear_i9_field = $(this).closest('.field').siblings('.invoice-details').find('[data-invoice="invoice_rear_i9_version_id"]')
  if value_for == "front"
    front_i9_field.val($(this).find(':selected').val())
    rear_i9_field.val("")
  else if value_for == "rear"
    rear_i9_field.val($(this).find(':selected').val())
    front_i9_field.val("")
  else if value_for == "both"
    value = $(this).find(':selected').val().split(",")
    front_i9_field.val(value[0])
    rear_i9_field.val(value[1])

$(document).on 'change', '[data-behaviour="i9_protocol_no"]', () ->
  $(this).attr("data-invoice-no", $(this).val())

$(document).on 'show.bs.modal', '#edit-tds-amount', () ->
  $('.form-control').removeClass('is-valid')

$(document).on 'change', '[data-behaviour="select_payment_type"]', () ->
  selected_val = $(this).val()
  if selected_val == 'cash'
    $('#payment_reference_no').val('').addClass('hidden')
  else
    $('#payment_reference_no').val('').removeClass('hidden')

$(document).on 'click', '[data-add="invoice-item"]', () ->
  i9_fields = $('[data-select="I9"]')
  i9_fields.each (index) ->
    $(this).select2 'destroy'
    $(this).attr("id", "i9-" + index).select2()
