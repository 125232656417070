filter_link_presence = () ->
  $('[data-behaviour="clear-filters"]').addClass('hidden')
  $('[data-behaviour="filter"]').each ->
    if $(this).val() != ""
      $("[data-behaviour='clear-filters']").removeClass('hidden')

current_request = null
window.select2_form_input_ui = ->
  $('.form-select-label-group select').each ->
    if $(this).val().length != 0
      $(this).parent().find('.select2-container').addClass('placeholder-padding')
    else
      $(this).parent().find('.select2-container').removeClass('placeholder-padding')

cocoon_form_validation = (form_class, submit) ->
  empty_cocoon_fields = []
  $('.' + form_class + ' .cocoon_validation_messages').addClass('hidden')
  $('.' + form_class + ' .nested-fields').each ->
    empty_field_counter = 0
    cocoon_fields_length = $(this).find('.cocoon_validation').length
    $(this).find('.cocoon_validation').each ->
      if $(this).val() == ''
        empty_field_counter = empty_field_counter + 1
    unless cocoon_fields_length == empty_field_counter
      $(this).find('.cocoon_validation').each ->
        if $(this).hasClass('select-2')
          $(this).removeClass('duplicated-element')
          $(this).siblings('.select2-container').removeClass('error-field')
        else
          $(this).removeClass('error-field')
        if $(this).val() == ''
          if $(this).hasClass('select-2')
            $(this).siblings('.select2-container').addClass('error-field')
          else
            $(this).addClass('error-field')
          submit.push(true)
          empty_cocoon_fields.push(true)
  if empty_cocoon_fields.length > 0
    $('.' + form_class + ' .cocoon_validation_messages').text('Please enter all fields outlined in red').removeClass('hidden')
  return submit


$(document).on 'click', '.export_btn', () ->
  controller_name = $(this).data('controller')
  if localStorage.getItem("export_ids") == 'select_all'
    selected_ids = "&ids=select_all"
    if location.search.includes('filters')
      selected_ids += location.search.replace('?', '&')
  else
    selected_ids = "&ids=" + JSON.parse(localStorage.getItem("export_ids"))
  window.location.href = "/" + controller_name + "/export_" + controller_name + "?&template=false&page=false" + selected_ids

$(document).on "turbolinks:load", () ->
  $('.remove_customer_invoice').remove()
  $('.select-2').select2()
  $('.datepicker').datepicker({
    format: 'M dd, yyyy',
    todayHighlight: true,
    autoclose: true,
  }).attr('readonly', true)
  $('.date-range-picker').attr('readonly', true)
  localStorage.removeItem("export_ids")

$(document).on 'change keypress keyup keydown paste cut', '[data-behaviour="filter"]', (event) ->
  $ -> filter_link_presence()

$(document).on 'click', '.select2', () ->
  $(".select2-search--dropdown").each ->
    $(this).addClass('ulsa-blue-border')
    unless $(this).hasClass("d-flex")
      $(this).addClass("d-flex align-items-center").append('<span class="fa-magnifying-glass font-size-14"></span>')

$(document).on 'change', '[data-choose="select-option"]', () ->
  $ -> select2_form_input_ui()

$(document).on 'click', '[data-open="show-modal"]', () ->
  $('.ulsa-panel-wrap').css('transform', 'translateX(0%)')
  $('.ulsa-panel-backdrop').css('transform', 'translateX(0%)')
  $('.ulsa-panel-wrap').css('z-index', '99')
  $('.ulsa-panel-backdrop').css('z-index', '99')

$(document).on 'click', '[data-behaviour="ulsa-panel-backdrop"]', (e) ->
  $('.ulsa-panel-wrap').css('transform', 'translateX(100%)')
  $('.ulsa-panel-backdrop').css('transform', 'translateX(100%)')
  controller = $(this).data("controller")
  window.history.pushState("", "", "/" + controller + location.search)

$(document).on 'click', '[data-filter="search"]', (e) ->
  e.preventDefault()
  $('[data-field="search"]').removeClass('hidden').focus()
  $('[data-remove="search"]').removeClass('hidden')
  $('.search-filter-container').addClass('search-field-line')
  e.stopPropagation()

$(document).click (event) ->
  unless event.target.closest('[data-field="search"], [data-remove="search"]')
    $('[data-field="search"]').addClass('hidden')
    $('[data-remove="search"]').addClass('hidden')
    $('.search-filter-container').removeClass('search-field-line')

$(document).on 'click', '[data-remove="search"]', () ->
  $('[data-field="search"]').val('').focus().keyup()

$(document).on 'click', '.select2', () ->
  $('.select2-search__field').attr('placeholder', 'Search').addClass('placeholder-style')

$(document).on 'click', '[data-toggle="modal"]', () ->
  $('.select-2').each ->
    if $(this).val() != ''
      $(this).val('').trigger('change')
    $(this).siblings('.select2-container').removeClass('error-field')
  $('.form-control').not('.select-2, .excluded-field').each ->
    $(this).val('')
    $(this).removeClass('error-field')
  $('.error-color').each ->
    $(this).addClass('hidden')
  $('.custom-validations').html('')

# --------------------- form submission through ajax ---------------------

$(document).on 'click', '.form_submit', (e) ->
  e.preventDefault()
  $('.custom-validations').html('')
  button_element = $(this)
  submit = []
  form_class = $(this).data('class-name')
  path_name = $(this).data('path-name')
  button_name = $(this).data('button-name')
  active_tab = $('[data-tab="order-form"]').find(".tab.active").attr("href")
  $('.' + form_class + ' .input_validation').each ->
    class_name = $(this).data('class-name')
    $('.error-color.' + class_name).addClass('hidden')
    $(this).siblings('.select2-container').removeClass('error-field')
    $(this).removeClass('error-field')
    $('.custom_invoice_error').addClass('hidden')

    if $(this).val() == '' && $(this).data('class-name') == 'customer_invoice_ids'
      a = 0
      $('[data-class-name="customer_invoice_ids"]').filter('.input_validation').each ->
        if $(this).val() != ''
          a =+ 1
      if a == 0 && $('[data-class-name="customer_invoice_ids"]').length > 1
        $(this).siblings('.select2-container').addClass('error-field')
        $('.' + class_name).removeClass('hidden')
        submit.push(true)
        $('.custom_invoice_error').removeClass('hidden').text('Please enter all fields outlined in red')
    else if $(this).val() == '' && $(this).hasClass('select-2')
      $(this).siblings('.select2-container').addClass('error-field')
      $('.' + class_name).removeClass('hidden')
      submit.push(true)
    else if $(this).val() == ''
      $(this).addClass('error-field')
      $('.error-color.' + class_name).removeClass('hidden')
      submit.push(true)
    if form_class == "edit_tds_amount"
      if parseFloat($(this).val()) == 0
        $('.error-color.' + class_name).removeClass('hidden').text('TDS amount must be greater than 0')
        submit.push(true)

  if $('.' + form_class).find('.form-table-container').length > 0
    submit = cocoon_form_validation(form_class, submit)
  $(this).prop('disabled', true).text('Loading ...').prepend("<i class='fa fa-spinner fa-spin mr-2'></i>")
  if submit.length == 0
    if form_class == 'edit_tds_amount'
      type = 'PATCH'
    else
      type = 'POST'
    if form_class == 'ulsa-login-form' || active_tab == '#upload-protocol'
      $('.' + form_class).submit()
    else if form_class == 'edit_i9_order_form'
      i9_order_item_id = $(this).data('i9-order-item-id')
      $.ajax '/i9_order_items/' + i9_order_item_id + '/check_if_invoiced',
      type: 'GET'
      data: $('.' + form_class).serialize()
      success: (data, jqxhr, textStatus) ->
        if data["is_zero"] == 'has_one_order_item'
          $('#edit-i9-order-item').modal('toggle')
          $.jGrowl('Cannot be deleted.', {theme: 'error'})
        else
          path_name = '/' + path_name + '/'
          $.ajax path_name,
            type: 'POST'
            data: $('.' + form_class).serialize()
    else
      path_name = '/' + path_name + '/'
      $.ajax path_name,
        type: 'POST'
        data: $('.' + form_class).serialize()
        error: (json, jqxhr, textStatus, errorThrown) ->
          button_element.prop('disabled', false).text(button_name)
          error = JSON.parse(json.responseText)
          $.each error, (key, value) ->
            if key == 'duplicated_types_arr' || key == 'i9_versions.duplicated_types_arr'
              for value in value[0]
                $('[data-type="' + value + '"]').addClass('duplicated-element')
            if $('.error-color.' + key)[0]
              $('.error-color.' + key).removeClass('hidden').text(value.join(', '))
              $('[' + 'data-class-name=' + key + ']').addClass('error-field')
            else if key == 'duplicated_parts_arr' || key == 'i9_versions.duplicated_parts_arr'
              for value in value[0]
                $('[data-part-id="' + value + '"]').addClass('duplicated-element')
                $('[data-invoice-no="' + value + '"]').addClass('duplicated-element')
                $('[data-type="' + value + '"]').addClass('duplicated-element')
            else if key == 'duplicated_invoice_arr'
              for value in value[0]
                $('[data-invoice-no="' + value + '"]').addClass('duplicated-element')
            else if key == 'exceeding_quantity_arr'
              for value in value[0]
                $('[data-invoice-no="' + value + '"]').closest('.field').siblings('.i9_quantity_no').find('[data-behaviour="i9_quantity_no"]').addClass('quantity-error')
            else
              if $('.following-text').text() == ''
                $('.' + form_class + ' .custom-validations').append('<p class="following-text">Check the following errors:</p>').removeClass('hidden')
              if key == 'current_password'
                key = 'old_password'
              unless key == "duplicated_types_arr" || key == "i9_versions.duplicated_parts_arr" || key == "i9_versions.duplicated_types_arr"
                error_title = key.split('_').join(' ')
                error_title = error_title.charAt(0).toUpperCase() + error_title.slice(1)
                switch error_title
                  when 'I9 versions.parts'
                    error_title = 'Parts'
                  when 'I9 versions.type'
                    error_title = 'Type'
                $('.' + form_class + ' .custom-validations').append('<li>' + error_title + ' - ' + value + '</li>')

  else
    button_element.prop('disabled', false).text(button_name)

# ------------------------ XXXXXXX ---------------------------------------

$(document).on 'click', '[data-behavior="show-modal"]', () ->
  record_id = $(this).data('record-id')
  request_url = $('.side_panel').data('request-url') + record_id
  show = $(this).data('show')
  if show == "customer_invoice_in_inward_dc"
    request_url = "/customer_invoices/" + record_id
  current_request = $.ajax request_url + '/detail_section',
  type: 'GET'
  success: (data, jqxhr, textStatus) ->
    if show == "customer_invoice_in_inward_dc"
      $.when($('#customer_invoice_in_inward_dc_panel').html data).then ->
        $('.ulsa-panel-wrap-inward-customer').css('transform', 'translateX(0%)')
        $('.ulsa-panel-backdrop-inward-customer').css('transform', 'translateX(0%)')
    else
      $.when($('.side_panel').html data).then ->
        $ -> window.open_show_modal()
        window.history.pushState("", "", request_url + location.search)

# -------------------------  inward dc adding customer invoices form ---------------
index_fill = () ->
  index = 1
  $('#customer_invoice_items *').filter('.serial-text').each ->
    $(this).text(index)
    index = index + 1

$(document).on 'click', '.add_invoice_btn', (e) ->
  out_htm = $('.customer_invoice_select')[0].outerHTML
  random_id = "customer_invoice_id_" + Math.floor(Math.random() * 100)
  out_htm = out_htm.replace(/inward_delivery_challan_customer_invoice_ids/g, random_id)
  divp = "<div class='d-flex'><div class='s-no'><div class='serial-text'></div></div><div class='invoice_div'>" + out_htm + '</div><div>' + "<div class='remove_customer_invoice fa-xmark pointer pt-3 pl-2'>  </div></div>"
  $('.form-sub').append(divp)
  $('#' + random_id).select2()
  $ -> index_fill()
  $('.select-2').select2()

$(document).on 'click', '.remove_customer_invoice', (e) ->
  $(this).parent().parent().remove()
  $ -> index_fill()


$(document).on 'change', '.customer_invoice_select', (e) ->
  customer_invoice_ids = []
  $('.customer_invoice_select').each ->
    customer_invoice_ids.push($(this).val())
  $('#customer_invoice_input').val(customer_invoice_ids)

# ----------------------------------- end -------------------------------------------
window.open_show_modal = () ->
  $('.ulsa-panel-wrap').css('transform', 'translateX(0%)')
  $('.ulsa-panel-backdrop').css('transform', 'translateX(0%)')

$(document).on 'keydown', 'input[type=number]', (e) ->
  if !((e.keyCode > 95 and e.keyCode < 106) or (e.keyCode > 47 and e.keyCode < 58) or e.keyCode == 8 or e.keyCode == 110 or e.keyCode == 190)
    e.preventDefault()

$(document).on 'click', '.dropdown-drp-icon-container', () ->
  $(this).siblings('.date-range-picker').click()

$(document).on 'change', '.daterangepicker-input', () ->
  $(this).find('.date-range-picker').removeClass('drp-active')
  $(this).find('.date-range-picker-2').removeClass('drp-active')
  $(this).width('190px')

$(document).on 'keyup', 'a, button, .select2-selection, input[type=submit], input[type=text]', () ->
  $(this).addClass('focusOutline').blur ->
    $(this).removeClass 'focusOutline'

$(document).on 'click', '[data-behaviour="select-all-part-invoice"]', () ->
  if $('[data-behaviour="select-all-part-invoice"]:checked').length > 0
    $('[data-behaviour="select-part-invoice"]').prop('checked', true)
    localStorage.setItem("export_ids", 'select_all')
    $('.export_btn').removeClass('hidden')
  else
    $('[data-behaviour="select-part-invoice"]').prop('checked', false)
    localStorage.setItem("export_ids", '')
    $('.export_btn').addClass('hidden')

$(document).on 'click', '[data-behaviour="select-part-invoice"]', () ->
  if localStorage.getItem("export_ids") == "" || localStorage.getItem("export_ids") == null
    selected_ids = []
  else
    selected_ids = JSON.parse(localStorage.getItem("export_ids"))
  index_of_element = selected_ids.indexOf($(this).data('id'))
  if $(this).prop('checked') && index_of_element == -1
    selected_ids.push($(this).data('id'))
    localStorage.setItem("export_ids", JSON.stringify(selected_ids))
  else if $(this).prop('checked') == false && index_of_element > -1
    if index_of_element == 0 && selected_ids.length == 1
      selected_ids.splice(0, 0)
      localStorage.setItem("export_ids", '')
      $('.export_btn').addClass('hidden')
    else
      selected_ids.splice(index_of_element, 1)
      localStorage.setItem("export_ids", JSON.stringify(selected_ids))
  if $('[data-behaviour="select-part-invoice"]:checked').length > 0
    $('.export_btn').removeClass('hidden')
  else
    $('[data-behaviour="select-all-part-invoice"]').prop('checked', false)

$(document).on 'click', '.ulsa-delete-btn', () ->
  $(this).prop('disabled', true).addClass('opacity-60').text('Loading ...').prepend("<i class='fa fa-spinner fa-spin mr-2'></i>")
