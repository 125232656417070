// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jgrowl")
require('spotlight_search')
require('moment')
require('daterangepicker')
require('bootstrap-datepicker')

import jQuery from 'jquery';
window.$ = jQuery
window.jQuery = jQuery
import 'jquery/dist/jquery.min';
import 'bootstrap/dist/js/bootstrap';
import 'select2';
import '../application/coffee_scripts/orders.coffee';
import '../application/coffee_scripts/scaffolds.coffee';
import '../application/coffee_scripts/customer_invoices.coffee';
import '../application/coffee_scripts/i9_sub_assemblies.coffee';
import '../application/coffee_scripts/invoices.coffee';
import '../application/coffee_scripts/inward_delivery_challans.coffee';
import '../application/coffee_scripts/outward_delivery_challans.coffee';
import '../application/coffee_scripts/parts.coffee';
import '../application/coffee_scripts/users.coffee';
import '../application/javascripts/spotlight_search.js';
import '@fortawesome/fontawesome-free/js/all.js';
import './cocoon.js';

$.jGrowl.defaults.position = 'bottom';
$.jGrowl.defaults.theme = 'notice';


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
