current_request = null

$(document).on "turbolinks:load", () ->
  $('[data-toggle="tooltip"]').tooltip()
  if $('#part_side_panel').find('#part_show').length
     $('.ulsa-panel-wrap').css('transform', 'translateX(0%)')
     $('.ulsa-panel-backdrop').css('transform', 'translateX(0%)')

$(document).on 'click', '[data-behavior="parts-show-modal"]', () ->
  part_id = $(this).data('part-id')
  current_request = $.ajax '/parts/' + part_id + '/part_side_page',
  type: 'GET'
  data: {

  }
  beforeSend: ->
    if current_request != null
      current_request.abort()
    return
  success: (data, jqxhr, textStatus) ->
    $.when($('#part_side_panel').html data).then ->
      $('.ulsa-panel-wrap').css('transform', 'translateX(0%)')
      $('.ulsa-panel-backdrop').css('transform', 'translateX(0%)')
      window.history.pushState("","","/parts/" + part_id + location.search)

$(document).on 'keyup', '[data-fetch="quantity"]', () ->
  id = $(this).closest('.field').siblings().find('[data-select="part"]').val()
  quantity = $(this).val()
  element = $(this)
  unless id == ''
    current_request = $.ajax '/parts/' + id + '/fetch_price.json',
    type: 'GET'
    data: {
      quantity: quantity
    }
    beforeSend: ->
      if current_request != null
        current_request.abort()
      return
    success: (data, jqxhr, textStatus) ->
      element.closest('.field').siblings().find('[data-fill="value"]').val(data.total_value)

$(document).on 'change', '[data-select="part"]', () ->
  $(this).attr("data-part-id", $(this).val())
  $(this).closest('.field').siblings().find('[data-fetch="quantity"]').val('')
  $(this).closest('.field').siblings().find('[data-fill="value"]').val('')

$(document).on 'click', '[data-change="form-tab"]', () ->
  $('[data-change="form-tab"]').removeClass('active')
  $(this).addClass('active')
